











import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class LabelDataRow extends Vue {
  @Prop({ required: true })
  private label!: number

  @Prop({ default: null })
  private data!: string
}
