import { sortBy } from 'lodash'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import {
  EducationLargeTaskQuestionAnswerResource,
  EducationLargeTaskQuestionSequenceResource,
  EducationLargeTaskQuestionUserAnswerResource,
  EducationLargeTaskTestQuestionResource, NameValueResource,
  TaskQuestionType,
} from '@/store/types'
import { alphabet, alphabetEng } from '@/utils/constants'
import { AnswerResource, QuestionType, SequenceResource, SingleQuestionResource } from '@/store/types/schema.training'

@Component
export default class TestQuestionResultMixin extends Vue {
  @Prop({ required: true })
  public subject!: NameValueResource

  // Без просмотра ответов мастера
  @Prop({ default: false })
  protected readonly!: boolean

  protected getQuestionAnswer (question: EducationLargeTaskTestQuestionResource) {
    switch (question.type.value) {
    case TaskQuestionType.EMPTY: return 'Без ответа'
    case TaskQuestionType.TEXT: return question.answers.map(answer => answer.text).join(', ')
    case TaskQuestionType.RATIO: {
      return question.answers.map((answer: EducationLargeTaskQuestionAnswerResource) => {
        const questionSequence = question.sequences.find((sequence: EducationLargeTaskQuestionSequenceResource) => sequence.position === +(answer.sequencePosition || 0))
        return `${answer ? this.subject.value === 9 ? alphabetEng[answer.position - 1] : alphabet[answer.position - 1] : ''}${questionSequence ? questionSequence.position : ''}`
      }).join('  ')
    }
    case TaskQuestionType.MULTIPLE: {
      const rightAnswers = question.answers.filter((answer: EducationLargeTaskQuestionAnswerResource) => answer.isCorrect)
      return rightAnswers.map((answer: EducationLargeTaskQuestionAnswerResource) => answer.position).join('  ')
    }
    case TaskQuestionType.SINGLE: {
      const rightAnswer = question.answers.find((answer: EducationLargeTaskQuestionAnswerResource) => answer.isCorrect)
      return rightAnswer ? rightAnswer.position : 'Нет правильного ответа'
    }
    case TaskQuestionType.SEQUENCE: {
      const sortedAnswers = sortBy(question.answers, ['rightPosition'])
      return sortedAnswers.map((answer: EducationLargeTaskQuestionAnswerResource) => answer.position).join('  ')
    }
    }
  }

  protected getTrainingQuestionAnswer (question: SingleQuestionResource) {
    switch (question.type) {
    case QuestionType.EMPTY: return 'Без ответа'
    case QuestionType.TEXT: return question.answers.map(answer => answer.text).join(', ')
    case QuestionType.RATIO: {
      return question.answers.map((answer: AnswerResource) => {
        const questionSequence = question.sequences.find((sequence: SequenceResource) => sequence.position === +(answer.sequencePosition || 0))
        return `${answer ? this.subject.value === 9 ? alphabetEng[answer.position - 1] : alphabet[answer.position - 1] : ''}${questionSequence ? questionSequence.position : ''}`
      }).join('  ')
    }
    case QuestionType.MULTIPLE: {
      const rightAnswers = question.answers.filter((answer: AnswerResource) => answer.isCorrect)
      return rightAnswers.map((answer: AnswerResource) => answer.position).join('  ')
    }
    case QuestionType.SINGLE: {
      const rightAnswer = question.answers.find((answer: AnswerResource) => answer.isCorrect)
      return rightAnswer ? rightAnswer.position : 'Нет правильного ответа'
    }
    case QuestionType.SEQUENCE: {
      const sortedAnswers = sortBy(question.answers, ['rightPosition'])
      return sortedAnswers.map((answer: AnswerResource) => answer.position).join('  ')
    }
    }
  }

  protected getQuestionUserAnswer (question: EducationLargeTaskTestQuestionResource) {
    switch (question.type.value) {
    case TaskQuestionType.EMPTY: return 'Нет ответа'
    case TaskQuestionType.TEXT: return question.userAnswers && question.userAnswers.length ? question.userAnswers[0].value : 'Нет ответа'
    case TaskQuestionType.RATIO: {
      return question.userAnswers.length > 0
        ? question.userAnswers.map((userAnswer: EducationLargeTaskQuestionUserAnswerResource) => {
          const questionAnswer = question.answers.find((answer: EducationLargeTaskQuestionAnswerResource) => answer.id === userAnswer.value)
          const questionSequence = question.sequences.find((sequence: EducationLargeTaskQuestionSequenceResource) => sequence.id === userAnswer.sequenceId)
          return `${questionAnswer ? this.subject.value === 9 ? alphabetEng[questionAnswer.position - 1] : alphabet[questionAnswer.position - 1] : ''}${questionSequence ? questionSequence.position : ''}`
        }).join('  ')
        : 'Нет ответа'
    }
    case TaskQuestionType.MULTIPLE:
    case TaskQuestionType.SINGLE:
    case TaskQuestionType.SEQUENCE: {
      return question.userAnswers.length > 0
        ? question.userAnswers.map((userAnswer: EducationLargeTaskQuestionUserAnswerResource) => {
          const questionAnswer = question.answers.find((answer: EducationLargeTaskQuestionAnswerResource) => answer.id === userAnswer.value)
          return questionAnswer ? questionAnswer.position : ''
        }).join('  ')
        : 'Нет ответа'
    }
    }
  }

  protected isAnswerMissed (answer: EducationLargeTaskQuestionAnswerResource, question: EducationLargeTaskTestQuestionResource) {
    return answer.isCorrect && !question.userAnswers.find((userAnswer) => userAnswer.value === answer.id)
  }

  protected isAnswerEmpty (answer: EducationLargeTaskQuestionAnswerResource, question: EducationLargeTaskTestQuestionResource) {
    return !answer.isCorrect && !question.userAnswers.find((userAnswer) => userAnswer.value === answer.id)
  }
}
