











































import { Component, Prop, Vue } from 'vue-property-decorator'

import ButtonArrow from '@/components/_uikit/buttons/ButtonArrow.vue'
import FilesList from '@/components/FilesList.vue'
import Player from '@/components/_uikit/Player.vue'
import QuestionMultipleResult from '@/components/views/exercise/question/QuestionMultipleResult.vue'
import QuestionRatioView from '@/components/views/exercise/question/QuestionRatioView.vue'
import QuestionResult from '@/components/views/exercise/question/QuestionResult.vue'
import QuestionSequenceView from '@/components/views/exercise/question/QuestionSequenceView.vue'
import QuestionSingleResult from '@/components/views/exercise/question/QuestionSingleResult.vue'
import { EducationLargeTaskTestQuestionResource, NameValueResource, TaskQuestionType } from '@/store/types'
import SystemModule from '@/store/modules/system'
import { downloadOriginalFile } from '@/utils/services/config'

@Component({
  components: {
    ButtonArrow,
    FilesList,
    Player,
    QuestionMultipleResult,
    QuestionRatioView,
    QuestionResult,
    QuestionSequenceView,
    QuestionSingleResult,
  },
})
export default class TestQuestionResultView extends Vue {
  @Prop({ required: true })
  private question!: EducationLargeTaskTestQuestionResource & { number: number }

  @Prop({ required: true })
  private subject!: NameValueResource

  @Prop({ default: 1 })
  private formID!: number

  @Prop({ default: () => ([]) })
  private questionsList!: EducationLargeTaskTestQuestionResource[]

  @Prop({ default: null })
  private index!: number | null

  // Без просмотра ответов мастера
  @Prop({ default: false })
  private readonly!: boolean

  // Показывать балл за вопрос
  @Prop({ default: true })
  private showPoint!: boolean

  // Находится ли компонент в модалке, нужно для скролла к вопросу
  @Prop({ default: false })
  private isModal!: boolean

  private get imageFiles() {
    return this.question.media.filter(media => media.type === 'image')
  }

  private get audioFiles() {
    return this.question.media.filter(media => media.type === 'audio')
  }

  private get mediaFiles() {
    return this.subject.value !== 14 ? this.question.media.filter(media => media.type === 'file') : this.question.media.filter(media => media.type === 'file').map(media => ({
      ...media,
      url: `${downloadOriginalFile}${media.id}`,
    }))
  }

  private get mediaCount() {
    return this.imageFiles.length
  }

  // Нечетное ли кол-во медиа
  private get isEvenMediaCount() {
    return Boolean(this.mediaCount % 2)
  }

  private get isQuestionMultiple () {
    return this.question.type.value === TaskQuestionType.MULTIPLE
  }

  private get isQuestionSingle () {
    return this.question.type.value === TaskQuestionType.SINGLE
  }

  private get isQuestionRatio () {
    return this.question.type.value === TaskQuestionType.RATIO
  }

  private get isQuestionSequence () {
    return this.question.type.value === TaskQuestionType.SEQUENCE
  }

  private get isQuestionNoAnswer() {
    return this.question.type.value === TaskQuestionType.NO_ANSWER
  }

  private get isManager () {
    return SystemModule.interface === 'manager'
  }

  private get relatedQuestionNumber() {
    if (this.question.relatedUuid) {
      return this.questionsList.findIndex(q => q.uuid === this.question.relatedUuid) + 1
    }
    return 0
  }

  private scrollToRelatedQuestion(relatedUuid: string) {
    this.$bus.$emit(`${this.isModal ? 'scrollIntoViewModal' : 'scrollIntoView'}`, relatedUuid)
  }
}
