


















import { Component, Prop, Vue } from 'vue-property-decorator'

// store
import { EducationLargeTaskCodingQuestionResource } from '@/store/types'
import { downloadOriginalFile } from '@/utils/services/config'

@Component
export default class TaskCodingQuestion extends Vue {
  @Prop({ required: true })
  private index!: number

  @Prop({ required: true })
  private question!: EducationLargeTaskCodingQuestionResource

  private get mediaList() {
    return this.question.media.map(media => ({
      ...media,
      url: `${downloadOriginalFile}${media.id}`,
    }))
  }
}
